<script>
  import { selectedTag } from '../store.js';



  function selectTag(event) {
    event.preventDefault();
    const selectedCategory = event.currentTarget.getAttribute('category');
    if($selectedTag === selectedCategory){
      selectedTag.set('');
    } else {
      selectedTag.set(selectedCategory);
  }
    }

  export let componentData = [];

  export let dividerAbove = false;
  export let dividerBelow = false;

  let headline = 'Pressrum/arkiv.';
  switch (componentData.locale){
    case 'en':
      headline = 'Press/Archive'
      break;

    default:
      headline = 'Pressrum/arkiv'
  }


  let tags = componentData.tags.map(item => {
    return {
      title: item.attributes.FilterTitle,
      category: item.attributes.Category,
      link: '#' // Keeping the link hard-coded
    }
  });

</script>


<style lang="scss">
  .headline {
    margin: 50px 0 10px;

    @media screen and (min-width: 750px) {
      margin: 70px 0 20px;
    }

    h2 { margin: 0; }

    .buttons-wrapper {
      margin: 0;
      align-self: center;
    }
  }

  .pagination {
    margin-bottom: 15px;
  }
</style>

<section class="headline"
  class:divider-above={dividerAbove}
  class:divider-below={dividerBelow}>
  <div class="wrapper grid grid--2">
    <div>
      <h2>{headline}</h2>
    </div>
  
    <div class="flex flex-end--desktop">
      <div class="buttons-wrapper">
        {#each tags as tag}
          <a href="{tag.link}" category="{tag.category}" class="btn btn--outline-alt" on:click|preventDefault={selectTag}>{tag.title}</a>
        {/each}
      </div>
    </div>
  </div>
</section>